import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useEventID, useGeneratorQuery } from "wecall-config-lib";
import card_in_hamd from "../assets/card.webp";
import trustseal from "../assets/secured-logo.webp";
import rating from "../assets/Frame 63 (1).webp";
import { ROUTES, sessionStorageRoutes } from "../component/Constant";
import GetRingbaNumber from "../component/GetRingbaNumber";
import Timer from "../component/Timer";

function Congrats({ headline }) {
  const navigate = useNavigate();
  const { getEventId } = useEventID();

  const generatorQuery = useGeneratorQuery();

  const triggerContactEvent = () => {
    window.fbcFunc("track", "Contact", {
      eventID: getEventId(),
    });
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(sessionStorageRoutes.medicareMedicaid))
      return navigate({
        pathname: "../" + ROUTES.medicareMedicaid,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(sessionStorageRoutes.congrats, true);
  }, []);

  return (
    <>
      <GetRingbaNumber />

      <div className="question-item">
        <h1>Congratulations</h1>
        <div className="congrats">
          <h2>{headline}</h2>
          <p>Space is limited. Tap the button below to call and claim.</p>
          <a
            onClick={triggerContactEvent}
            href={
              "tel:" +
              (window.pnumber || window.domain_settings.rinbaUser?.number)
            }
            className="call_now"
          >
            {window.pnumber || window.domain_settings.rinbaUser?.number}
          </a>
          <p>
            This program has extremely limited capacity, so call us in the next{" "}
            <Timer /> to claim your subsidy before it's given to another
            applicant in line.
          </p>
          <img src={trustseal} alt=""></img>
        </div>
      </div>
      <div className="card-container">
        <div className="mainbg">
          <img src={card_in_hamd} alt=""></img>
        </div>
      </div>
      <div className="hero-section-image">
        <img src={rating} alt=""></img>
      </div>
    </>
  );
}

export default Congrats;
